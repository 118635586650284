<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />

    <b-card v-else>
      <template v-slot:header>
        <h5>Divisi</h5>
      </template>
      <div
        class="search-wrapper d-flex align-items-center justify-content-between"
      ></div>

      <!-- Table -->
      <b-table
        bordered
        hover
        show-empty
        :busy="isBusy"
        :items="divisi"
        :fields="fields"
        :perPage="perPage"
        responsive
        class="mb-3"
      >
        <template #empty="scope"> Data tidak ditemukan </template>

        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner
              small
              class="align-middle"
              variant="secondary"
            ></b-spinner>
            <span class="font-weight-bold text-secondary">
              Harap tunggu...</span
            >
          </div>
        </template>

        <template v-slot:cell(action)="row">
          <div class="no-wrap">
            <b-button
              size="sm"
              class="mr-1"
              variant="warning"
              v-b-tooltip.hover
              title="Edit"
              name="btnEditDivisi"
              @click="
                showModalEdit(
                  `${row.item.DivisiID}`,
                  `${row.item.kd_Divisi}`,
                  `${row.item.nm_Divisi}`,
                  `${row.item.UserInput}`
                )
              "
            >
              <i class="fa fa-pencil"></i>
            </b-button>

            <b-button
              size="sm"
              class="ml-1"
              variant="danger"
              v-b-tooltip.hover
              title="Delete"
              name="btnDeleteDivisi"
              @click="
                showSweetAlertDelete(
                  `${row.item.DivisiID}`,
                  `${row.item.nm_Divisi}`
                )
              "
            >
              <i class="fa fa-trash"></i>
            </b-button>
          </div>
        </template>
      </b-table>

      <div
        class="pagination-wrapper d-flex align-items-center justify-content-between"
      >
        <b-input-group prepend="Menampilkan" class="mt-3 font-weight-bold">
          <b-input-group-append>
            <b-input-group-text class="bg-white">
              <strong class="mr-1">{{ totalViewText }}</strong>
              <span class="mr-1">dari</span>
              <strong>{{ rows }}</strong>
            </b-input-group-text>
            <b-input-group-text>entri</b-input-group-text>
          </b-input-group-append>
        </b-input-group>

        <b-pagination
          class="mb-0"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          @input="onPageChange"
        ></b-pagination>
      </div>
    </b-card>

    <!-- Modal Create -->
    <b-modal
      id="m-create-divisi"
      class="modal-lg"
      title="Divisi"
      ref="my-modal-divisi"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      hide-header
    >
      <b-form @submit.prevent="onConfirm">
        <b-form-group
          id="fg-create-name-divisi"
          label="Divisi"
          label-for="input-divisi"
          class="required"
        >
          <b-form-input
            id="input-divisi"
            type="text"
            v-model.trim="form.nm_Divisi"
            placeholder="Masukkan nama divisi"
          ></b-form-input>
          <span
            v-if="!$v.form.nm_Divisi.required && $v.form.nm_Divisi.$dirty"
            class="text-danger"
            >Kolom tidak boleh kosong</span
          >
        </b-form-group>

        <b-form-group
          id="fg-create-code-divisi"
          label="Kode Divisi"
          label-for="input-code-divisi"
          class="required"
        >
          <b-form-input
            id="input-code-divisi"
            type="text"
            v-model.trim="form.kd_Divisi"
            placeholder="Masukkan kode divisi"
          ></b-form-input>
          <span
            v-if="!$v.form.kd_Divisi.required && $v.form.kd_Divisi.$dirty"
            class="text-danger"
            >Kolom tidak boleh kosong</span
          >
        </b-form-group>

        <b-form-group
          id="fg-create-user-input"
          label="User"
          label-for="input-user"
          class="required"
        >
          <b-form-input
            id="input-user"
            type="text"
            v-model.trim="form.UserInput"
            placeholder="Masukkan nama user"
          ></b-form-input>
          <span
            v-if="!$v.form.UserInput.required && $v.form.UserInput.$dirty"
            class="text-danger"
            >Kolom tidak boleh kosong</span
          >
        </b-form-group>

        <div class="mt-4 text-right">
          <b-button variant="pgiBtn" type="submit">Simpan</b-button>
          <b-button class="ml-2" variant="danger" @click="hideModal"
            >Batal</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <!-- MODAL EDIT -->
    <b-modal
      id="m-edit-divisi"
      class="modal-lg"
      title="Divisi"
      ref="my-modal-divisi"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      hide-header
    >
      <b-form @submit.prevent="onConfirmEdit">
        <b-form-group
          id="fg-edit-divisi-name-divisi"
          label="Divisi"
          label-for="edit-name-divisi"
          class="required"
        >
          <b-form-input
            id="edit-name-divisi"
            type="text"
            v-model="formEdit.nm_Divisi"
          ></b-form-input>
          <span
            v-if="
              !$v.formEdit.nm_Divisi.required && $v.formEdit.nm_Divisi.$dirty
            "
            class="text-danger"
            >Kolom tidak boleh kosong</span
          >
        </b-form-group>

        <b-form-group
          id="fg-edit-code-divisi"
          label="Kode Divisi"
          label-for="edit-code-divisi"
          class="required"
        >
          <b-form-input
            id="edit-code-divisi"
            type="text"
            v-model.trim="formEdit.kd_Divisi"
            placeholder="Masukkan kode divisi"
          ></b-form-input>
          <span
            v-if="
              !$v.formEdit.kd_Divisi.required && $v.formEdit.kd_Divisi.$dirty
            "
            class="text-danger"
            >Kolom tidak boleh kosong</span
          >
        </b-form-group>

        <b-form-group
          id="fg-edit-user-input"
          label="User"
          label-for="edit-user"
          class="required"
        >
          <b-form-input
            id="edit-user"
            type="text"
            v-model.trim="formEdit.UserInput"
            placeholder="Masukkan nama user"
          ></b-form-input>
          <span
            v-if="
              !$v.formEdit.UserInput.required && $v.formEdit.UserInput.$dirty
            "
            class="text-danger"
            >Kolom tidak boleh kosong</span
          >
        </b-form-group>

        <div class="mt-4 text-right">
          <b-button variant="pgiBtn" type="submit">Simpan Perubahan</b-button>
          <b-button class="ml-2" variant="danger" @click="hideModal"
            >Batal</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      initStatus: 0,
      isBusy: true,
      filter: {
        nm_Divisi: null,
      },
      form: {
        nm_Divisi: null,
        kd_Divisi: null,
        UserInput: null,
      },
      formEdit: {
        DivisiID: null,
        nm_Divisi: null,
        kd_Divisi: null,
        UserInput: null,
      },
      formDelete: {
        activeId: null,
        activeName: null,
      },
      formManageDivisi: {
        devisi_id: null,
        devisi_name: null,
        id_kategori_fpp: [""],
      },
      fields: [
        { key: "nm_Divisi", label: "Divisi", sortable: true },
        {
          key: "kd_Divisi",
          label: "Kode Divisi",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "UserInput",
          label: "User",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          thClass: "nameOfTheClassThAction",
          tdClass: "nameOfTheClassTd",
        },
      ],
      divisi: [],
      optionsKategoriFPP: [],
      checked: [],
      isModalVisible: false,
      currentPage: 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: "",
      size: "",
      from: "",
      limit: "",
      loading: false,
      query: "",
    };
  },
  validations: {
    form: {
      nm_Divisi: {
        required,
      },
      kd_Divisi: {
        required,
      },
      UserInput: {
        required,
      },
    },

    formEdit: {
      nm_Divisi: {
        required,
      },
      kd_Divisi: {
        required,
      },
      UserInput: {
        required,
      },
    },
  },

  //----------------- Init Function -----------------//
  methods: {
    init: function () {
      this.initStatus = 0;

      // Get Divisi
      let divisi = this.$axios.get("api/admin/devisi").then((response) => {
        let datas = response.data.data;

        for (var data of datas.data) {
          this.divisi.push({
            DivisiID: data.DivisiID,
            kd_Divisi: data.kd_Divisi,
            nm_Divisi: data.nm_Divisi,
            UserInput: data.UserInput,
          });
        }

        this.perPage = datas.per_page;
        this.size = this.perPage;
        this.from = datas.from;
        this.limit = datas.to;

        this.updateTotalItem(datas.total);
      });

      Promise.all([divisi])
        .then(() => {
          this.initStatus = 1;
          this.toggleBusy();
        })
        .catch((error) => {
          console.log(error);
          this.initStatus = -1;
          this.toggleBusy();
        });

      // Get Kategori FPP
      this.$axios.get("api/internal-memo/kategori").then((response) => {
        let datas = response.data.data;

        for (var data of datas.data) {
          this.optionsKategoriFPP.push({
            id: data.id,
            name: data.name,
          });
        }
      });
    },

    //----------------- Pagination, Sorting, dan Filtering Function -----------------//
    onPageChange: function () {
      if (this.loading == false) {
        this.page = this.currentPage;
        this.query = "?page=" + this.page;
        this.getDivisi();
      }
    },

    toggleBusy() {
      if (this.isBusy == true) {
        this.isBusy = false;
      } else {
        this.isBusy = true;
      }
    },

    updateTotalItem: function (total) {
      this.perPage = this.size;
      this.rows = total;

      const resultFrom = this.from == null ? 0 : this.from;
      const resultLimit = this.limit == null ? 0 : this.limit;

      this.totalViewText = resultFrom + " - " + resultLimit;
    },

    //-----------------  Callback Function -----------------//
    getDivisi: function () {
      this.toggleBusy();

      this.$axios
        .get("api/admin/devisi" + this.query)
        .then((response) => {
          let datas = response.data.data;

          this.divisi = [];
          this.toggleBusy();

          for (var data of datas.data) {
            this.divisi.push({
              DivisiID: data.DivisiID,
              nm_Divisi: data.nm_Divisi,
              kd_Divisi: data.kd_Divisi,
              UserInput: data.UserInput,
            });
          }

          this.perPage = datas.per_page;
          this.size = this.perPage;
          this.from = datas.from;
          this.limit = datas.to;

          this.updateTotalItem(datas.total);

          this.resetForm();
        })
        .catch((error) => {
          console.log(error);

          this.toggleBusy();
        });
    },

    //-----------------  Reset Function -----------------//
    resetForm: function () {
      this.form = {
        nm_Divisi: null,
        kd_Divisi: null,
        UserInput: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    //-----------------  Hide Modal Function -----------------//
    hideModal: function () {
      this.$refs["my-modal-divisi"].hide();
      this.resetForm();
    },

    //-----------------  Confirm Create Function -----------------//
    onConfirm: function () {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      } else if (
        this.form.nm_Divisi != null &&
        this.form.kd_Divisi != null &&
        this.form.UserInput != null
      ) {
        this.showSweetAlertCreate();
        this.$bvModal.hide("m-create-divisi");
      }
    },

    //-----------------  Confirm Edit Function -----------------//
    onConfirmEdit: function () {
      this.$v.formEdit.$touch();

      if (this.$v.formEdit.$anyError) {
        return;
      } else if (
        this.formEdit.nm_Divisi != null &&
        this.formEdit.kd_Divisi != null &&
        this.formEdit.UserInput != null
      ) {
        this.showSweetAlertEdit();
        this.$bvModal.hide("m-edit-divisi");
      }
    },

    //-----------------  Confirm Manage Division -----------------//
    onConfirmManageDivisi: function () {
      if (this.formManageDivisi.id_kategori_fpp != null) {
        this.showSweetAlertManageDivisi();
        this.$bvModal.hide("m-manage-divisi");
      }
    },

    //-----------------  Create Function -----------------//
    showSweetAlertCreate: function () {
      var nameDivisi = this.form.nm_Divisi;
      var codeDivisi = this.form.kd_Divisi;
      var userInput = this.form.UserInput;

      var content = document.createElement("div");
      content.innerHTML =
        "<p style='text-align: left;'>Apakah anda yakin menambahkan data sebagai berikut: \n</p>" +
        "<div style='width: 100%; text-align: left; margin-top: 10px'>" +
        "<table class='small'>" +
        "<tr>" +
        "<td>Divisi</td>" +
        "<td style='width: 10%'>:</td>" +
        "<td>" +
        "<strong>" +
        nameDivisi +
        "</strong>" +
        "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>Kode Divisi</td>" +
        "<td>:</td>" +
        "<td>" +
        "<strong>" +
        codeDivisi +
        "</strong>" +
        "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>User Input</td>" +
        "<td>:</td>" +
        "<td>" +
        "<strong>" +
        userInput +
        "</strong>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</div>";

      this.$swal({
        title: "Konfirmasi",
        html: content,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isSubmitting = true;
          this.$axios
            .post("/api/admin/devisi", this.form)
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Divisi telah ditambahkan.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              } else {
                this.$swal({
                  title: "Gagal",
                  text: "Divisi gagal ditambahkan.",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }

              this.form = {};
              this.getDivisi();
            })
            .catch((error) => this.$helper.parseError(this, error))
            .finally(() => (this.isSubmitting = false));
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });

          this.resetForm();
        }
      });
    },

    //-----------------  Edit Function -----------------//
    showModalEdit: function (DivisiID, kd_Divisi, nm_Divisi, UserInput) {
      this.formEdit.DivisiID = DivisiID;
      this.formEdit.kd_Divisi = kd_Divisi;
      this.formEdit.nm_Divisi = nm_Divisi;
      this.formEdit.UserInput = UserInput;

      this.$bvModal.show("m-edit-divisi");
    },

    showSweetAlertEdit: function () {
      var nameDivisi = this.formEdit.nm_Divisi;
      var codeDivisi = this.formEdit.kd_Divisi;
      var userInput = this.formEdit.UserInput;

      var content = document.createElement("div");
      content.innerHTML =
        "<p style='text-align: left;'>Apakah anda yakin melakukan perubahan pada data berikut: \n</p>" +
        "<div style='width: 100%; text-align: left; margin-top: 10px'>" +
        "<table class='small'>" +
        "<tr>" +
        "<td>Divisi</td>" +
        "<td style='width: 5%'>:</td>" +
        "<td>" +
        "<strong>" +
        nameDivisi +
        "</strong>" +
        "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>Kode Divisi</td>" +
        "<td>:</td>" +
        "<td>" +
        "<strong>" +
        codeDivisi +
        "</strong>" +
        "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>User</td>" +
        "<td>:</td>" +
        "<td>" +
        "<strong>" +
        userInput +
        "</strong>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</div>";

      this.$swal({
        title: "Konfirmasi",
        html: content,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let formEdit = {
            DivisiID: this.formEdit.DivisiID,
            nm_Divisi: this.formEdit.nm_Divisi,
            kd_Divisi: this.formEdit.kd_Divisi,
            UserInput: this.formEdit.UserInput,
          };

          this.isSubmitting = true;
          this.$axios
            .post("/api/admin/devisi/" + this.formEdit.DivisiID, formEdit)
            .then((response) => {
              this.$bvModal.hide("m-edit-divisi");

              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Perubahan divisi berhasil.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              } else {
                this.$swal({
                  title: "Gagal",
                  text: "Perubahan divisi gagal.",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }

              this.form = {};
              this.getDivisi();
            })
            .catch((error) => this.$helper.parseError(this, error))
            .finally(() => (this.isSubmitting = false));
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    },

    //-----------------  Delete Function -----------------//
    showSweetAlertDelete: function (DivisiID, nm_Divisi) {
      this.formDelete.activeId = DivisiID;
      this.formDelete.activeName = nm_Divisi;

      var nameDivisi = this.formDelete.activeName;
      var content = document.createElement("div");
      content.innerHTML =
        'Apakah anda yakin ingin menghapus Kategori berikut: <strong>"' +
        nameDivisi +
        '"</strong>?';

      this.$swal({
        title: "Konfirmasi",
        html: content,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya. Hapus!",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios
            .delete("api/admin/devisi/delete/" + this.formDelete.activeId)
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Divisi telah dihapus.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              } else {
                this.$swal({
                  title: "Gagal",
                  text: "Divisi gagal dihapus.",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
              this.form = {};
              this.formDelete = {};
              this.getDivisi();
            });
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });
          this.resetForm();
        }
      });
    },

    showModalManageDivisi: function (DivisiID, nm_Divisi) {
      this.formManageDivisi.devisi_id = DivisiID;
      this.formManageDivisi.devisi_name = nm_Divisi;

      // Get Divisi Akses
      this.$axios
        .get("api/internal-memo/devisi-access/all")
        .then((response) => {
          let datas = response.data.data;

          for (var i = 0; i < datas.length; i++) {
            if (datas[i].devisi_id == DivisiID) {
              this.checked = datas[i].id_kategori_fpp;
            }
          }
        });

      this.$bvModal.show("m-manage-divisi");
    },

    showSweetAlertManageDivisi: function () {
      console.log(this.formManageDivisi.devisi_id);
      console.log(this.formManageDivisi.id_kategori_fpp);
    },
  },

  //-----------------  Initial Function -----------------//
  created: function () {
    this.init();
  },
};
</script>

<style lang="scss">
.nameOfTheClassTh {
  text-align: center;
}

.nameOfTheClassThAction {
  width: 10%;
  text-align: center;
}

.nameOfTheClassTd {
  text-align: center;
}

.required label::after {
  content: "*";
  color: #f79483;
}
</style>
